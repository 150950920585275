@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  overflow-x: hidden;
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: auto;
  scrollbar-color: #afb2bf;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 3px;
}

*::-webkit-scrollbar-track {
  background: #000814;
}

*::-webkit-scrollbar-thumb {
  background-color: #afb2bf;
  border-radius: 10px;
  visibility: hidden;
}

*:active::-webkit-scrollbar-thumb,
*:focus::-webkit-scrollbar-thumb,
*:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

/* Hide default styling of details/summary */
details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}

/* https://10015.io/tools/css-loader-generator */
.spinner {
  margin: 40px;
  width: 11.2px;
  height: 11.2px;
  animation: spinner-z355kx 1s infinite linear;
  border-radius: 11.2px;
  box-shadow: 28px 0px 0 0 #6EC9E0, 17.4px 21.8px 0 0 #6EC9E0, -6.2px 27.2px 0 0 #6EC9E0,
    -25.2px 12px 0 0 #6EC9E0, -25.2px -12px 0 0 #6EC9E0, -6.2px -27.2px 0 0 #6EC9E0,
    17.4px -21.8px 0 0 #6EC9E0;
}

@keyframes spinner-z355kx {
  to {
    transform: rotate(360deg);
  }
}


.codeblock1 {
  width: 372.95px;
  height: 257.05px;
  border-radius: 100%;
  left: calc(50% - 372.95px / 2 - 76.53px);
  top: calc(50% - 257.05px / 2 - 47.47px);
  background: linear-gradient(
    123.77deg,
    #8a2be2 -6.46%,
    #ffa500 59.04%,
    #f8f8ff 124.53%
  );
  opacity: 0.2;
  filter: blur(34px);
  transform: matrix(1, 0, -0.03, 1, 0, 0);
}
.codeblock2 {
  position: absolute;
  width: 372.95px;
  height: 257.05px;
  left: calc(50% - 372.95px / 2 - 76.53px);
  top: calc(50% - 257.05px / 2 - 47.47px);
  border-radius: 100%;
  background: linear-gradient(
    118.19deg,
    #1fa2ff -3.62%,
    #12d8fa 50.44%,
    #a6ffcb 104.51%
  );
  opacity: 0.2;
  filter: blur(34px);
  transform: matrix(1, 0, -0.03, 1, 0, 0);
}
.code-border {
  border: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(to right bottom, #ffffff38, #ffffff00);
  background: linear-gradient(
    111.93deg,
    rgba(14, 26, 45, 0.24) -1.4%,
    rgba(17, 30, 50, 0.38) 104.96%
  );
  /* backdrop-filter: blur(26px); */
}

@keyframes spinner-bulqg1 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
  }

  12.5% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 0%,
      100% 0%,
      100% 0%
    );
  }

  25% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      100% 100%,
      100% 100%
    );
  }

  50% {
    clip-path: polygon(
      50% 50%,
      0 0,
      50% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  62.5% {
    clip-path: polygon(
      50% 50%,
      100% 0,
      100% 0%,
      100% 0%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  75% {
    clip-path: polygon(
      50% 50%,
      100% 100%,
      100% 100%,
      100% 100%,
      100% 100%,
      50% 100%,
      0% 100%
    );
  }

  100% {
    clip-path: polygon(
      50% 50%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      50% 100%,
      0% 100%
    );
  }
}

@keyframes spinner-oaa3wk {
  0% {
    transform: scaleY(1) rotate(0deg);
  }

  49.99% {
    transform: scaleY(1) rotate(135deg);
  }

  50% {
    transform: scaleY(-1) rotate(0deg);
  }

  100% {
    transform: scaleY(-1) rotate(-135deg);
  }
}